import React from "react";
import Icon from './icons/icon';
import { socialMedia } from '../config';

const SocialMedia = () => {
  return (
    <div>
      <ul className="ml-1 mt-8 flex items-center">
        {socialMedia &&
          socialMedia.map(({ url, name }, i) => (
              <li className="mr-5 text-xs" key={i}>
                <a
                    className="block hover:text-slate-200"
                    href={url} aria-label={name} target="_blank" rel="noreferrer">
                  <Icon name={name} />
                </a>
              </li>
          ))}
      </ul>
    </div>
  )
}

export default SocialMedia;

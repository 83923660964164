import React from "react";

import About from "./components/About";
import Experience from "./components/Experience";
import Main from "./components/Main";
import NavBar from "./components/NavBar";
import Projects from "./components/Projects";
import SocialMedia from "./components/SocialMedia";

import "./App.css";

function App() {
  return (
      <div className="bg-gray-800 leading-relaxed text-slate-400 antialiased selection:bg-indigo-300 selection:text-indigo-900">
        <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0">
          <div className="lg:flex lg:justify-between lg:gap-4">
            <aside className="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
              <About />
              <NavBar />
              <SocialMedia />
            </aside>

            <section className="pt-6 lg:w-1/2 lg:py-24">
              <Main />
              <Experience />
              <Projects />
            </section>
          </div>
        </div>
      </div>
  );
}

export default App;

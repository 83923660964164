import React from "react";

const Experience = () => {
  return (
      <div id="experience">
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          <span
              className="absolute px-3 text-sm font-small font-bold uppercase text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-800">
              Experience
            </span>
        </div>
        <ol className="group/list">
          <li className="mb-12">
            <div className="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
              <header
                  className="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2"
                  aria-label="2019 to Present">2018 — Present
              </header>
              <div className="z-10 sm:col-span-6">
                <h3 className="font-medium leading-snug text-slate-200">
                  <div>
                    <span>Senior Software Engineer · </span>
                    <span className="inline-block">Justworks</span>

                  </div>
                  <div>
                    <div className="text-slate-500" aria-hidden="true">
                      Software Engineer
                    </div>
                  </div>
                </h3>
                <p className="mt-2 text-sm leading-normal">
                  Solving HR challenges for small business users.
                  Building and guiding product specifications, and translating them into detailed technical plans and specification documents.
                  Providing leadership within the engineering department through close collaboration, knowledge sharing, and mentorship.
                </p>
                <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300">
                      React
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300">
                      Ruby
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                    className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300">
                      SCSS
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300">
                      AWS
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300">
                      JavaScript
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="mb-12">
            <div className="group relative grid pb-1 transition-all sm:grid-cols-8 sm:gap-8 md:gap-4 lg:hover:!opacity-100 lg:group-hover/list:opacity-50">
              <header
                  className="z-10 mb-2 mt-1 text-xs font-semibold uppercase tracking-wide text-slate-500 sm:col-span-2"
                  aria-label="2018 to 2020">2018 — 2020
              </header>
              <div className="z-10 sm:col-span-6">
                <h3 className="font-medium leading-snug text-slate-200">
                  <div>
                    <span>Full Stack Teaching Assistant · </span>
                    <span className="inline-block">Pursuit</span>
                  </div>
                </h3>
                <p className="mt-2 text-sm leading-normal">
                  Provided code reviews, personalized feedback, and hands-on workshops to improve full stack web development skills for 32+ students.
                </p>
                <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300">
                      React
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">
                      CSS
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">
                      Node
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">
                      Express
                    </div>
                  </li>
                  <li className="mr-1.5 mt-2">
                    <div
                        className="flex items-center rounded-full bg-indigo-400/10 px-3 py-1 text-xs font-medium leading-5 text-indigo-300 ">
                      JavaScript
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ol>
      </div>
  )
}

export default Experience;
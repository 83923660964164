import React from "react";

const Logo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 954 816"
    >
      <title>Logo</title>
      <path
          fill="#212348"
          d="M498 817H1V1h954v816H498M181.841 477.273c.021 2.661.053 5.323.062 7.985.024 6.724.02 6.733 6.923 6.736 37.934.02 75.867.036 113.971.97v29.34H166.25v92.513c5.348 0 10.458 0 15.61.828.017 2.998.238 6.011 0 8.989-.321 4.027 1.064 5.54 5.309 5.533 57.79-.113 115.58-.116 173.369.003 4.325.01 5.35-1.633 5.285-5.579-.187-11.322.143-22.654-.162-33.97-.13-4.864 1.06-6.835 6.344-6.644 10.81.389 21.646.125 32.471.112 6.593-.007 6.614-.021 6.615-6.594.006-46.298.003-92.597.003-138.895 0-11.325-.09-22.651.047-33.974.042-3.413-1.083-4.873-4.604-4.66-3.485.21-6.992.033-10.668-.89v-14.568h-6.127c-35.923 0-71.846.004-107.77-.015-2.318 0-4.637-.18-7.014-1.107-.018-7.82.143-15.646-.127-23.457-.14-4.05 1.467-5.146 5.263-5.089 11.478.172 22.96.06 34.44.06 30.283 0 60.565.003 90.847-.006 2.803 0 5.769.564 5.754-3.98-.091-28.286-.067-56.571-.015-84.857.006-2.935-1.044-4.28-4.08-4.177-3.656.125-7.32.01-11.154-.905v-14.647H211.478V292h-45.23v184.387c5.36 0 10.479 0 15.593.886M556.032 260.93v-14.538h-92.789v368.427c5.346 0 10.46 0 15.615.828.015 2.497.038 4.995.044 7.492.017 6.937.014 6.952 6.757 6.954 26.308.007 52.616.004 78.923.002 7.168 0 7.18-.006 7.18-7.037.005-41.626.022-83.253-.03-124.879-.004-3.64.001-6.3 5.082-6.274 37.795.2 75.592.147 113.625 1.071v121.78c5.657 0 10.595 0 15.613.831.024 2.664.058 5.329.07 7.993.03 6.483.026 6.497 6.696 6.498 26.315.007 52.63.005 78.945.003 7.213 0 7.218-.008 7.218-7.44.002-117.752.001-235.503.001-353.255 0-1-.099-2.01.011-2.996.402-3.602-1.377-4.663-4.707-4.512-3.488.158-6.991-.037-10.708-.963V246.27h-93.22v138.238h-5.967c-23.484 0-46.967.028-70.45-.009-13.984-.022-27.967-.146-42.075-1.057-.037-14.655-.095-29.311-.107-43.967-.018-23.983-.096-47.967.076-71.95.032-4.354-1.266-6.082-5.695-5.677-3.301.301-6.656.015-10.108-.918z"
      />
      <path
          fill="#C4CCFB"
          d="M478.818 614.819h-15.575V246.392h92.79c0 4.697 0 9.618-.01 15.47-.01 41.359-.01 81.785-.01 122.413h15.977c13.984.078 27.967.202 41.951.224 23.483.037 46.966.01 70.45.01h5.966V246.27h93.22c0 5.049 0 9.847-.014 15.554-.054 2.905-.128 4.903-.128 6.9-.005 111.58-.004 223.161-.004 334.742 0 2.165-.176 4.346.031 6.491.341 3.534-1.147 4.585-4.582 4.533-11.155-.167-22.315-.102-33.473-.049-13.145.063-26.29.207-39.435.316h-15.533c0-41.116 0-81.448-.003-122.684-.004-5.82-.004-10.737-.004-15.624H555.844v138.016c-12 0-23.308-.045-34.615.011-14.137.07-28.274.225-42.411.343zM181.818 614.817h-15.57v-92.512h136.55c0-10.145 0-19.743-.003-30.26-.003-5.832-.003-10.743-.003-15.89-19.379 0-37.993-.03-56.606.007-21.447.044-42.894.147-64.34.225h-15.597V292h45.23v-45.672h184.406V339.199h-136.56v45.018h15.69c2.319.096 4.638.275 6.957.276 35.923.019 71.846.014 107.77.015h6.126c0 5.036 0 9.802.005 15.488.004 56.628.004 112.336.004 168.629h-46.203v45.83h-6.97c-51.476.001-102.95-.002-154.425.018-2.154 0-4.308.224-6.461.344z"
      />
      <path
          fill="#254EAA"
          d="M706.012 615.172c13.105-.524 26.25-.668 39.395-.73 11.158-.054 22.318-.12 33.473.048 3.435.052 4.923-1 4.582-4.533-.207-2.145-.031-4.326-.031-6.491 0-111.58-.001-223.162.004-334.742 0-1.997.074-3.995.239-6.458 3.62-.425 7.124-.23 10.612-.388 3.33-.151 5.11.91 4.707 4.512-.11.987-.01 1.997-.01 2.996 0 117.752 0 235.503-.002 353.255 0 7.432-.005 7.44-7.218 7.44-26.315.002-52.63.004-78.945-.003-6.67-.001-6.666-.015-6.696-6.498a504.155 504.155 0 0 0-.11-8.408z"
      />
      <path
          fill="#204BA8"
          d="M181.839 615.231c2.132-.534 4.286-.757 6.44-.758 51.475-.02 102.95-.017 154.424-.017h6.971v-45.831h46.203c0-56.293 0-112 .085-168.17 3.582-.456 7.09-.28 10.574-.49 3.521-.212 4.646 1.248 4.604 4.66-.137 11.324-.048 22.65-.047 33.975 0 46.298.003 92.597-.003 138.895-.001 6.573-.022 6.587-6.615 6.594-10.825.013-21.66.277-32.471-.112-5.283-.19-6.475 1.78-6.344 6.643.305 11.317-.025 22.65.162 33.971.065 3.946-.96 5.588-5.285 5.58-57.79-.12-115.58-.117-173.37-.004-4.244.008-5.63-1.506-5.308-5.533.238-2.978.017-5.991-.02-9.403z"
      />
      <path
          fill="#1E4AA8"
          d="M478.838 615.233c14.117-.532 28.254-.686 42.39-.757 11.308-.056 22.615-.011 34.616-.011V476.449h134.588c0 4.887 0 9.804-.115 15.175-37.911.428-75.708.481-113.503.281-5.08-.027-5.086 2.633-5.081 6.274.051 41.626.034 83.253.03 124.879 0 7.03-.013 7.036-7.18 7.037-26.308.002-52.616.005-78.924-.002-6.743-.002-6.74-.017-6.757-6.954a812.266 812.266 0 0 0-.064-7.906z"
      />
      <path
          fill="#204AA6"
          d="M274.986 383.802c-5.092.415-10.214.415-15.662.415V339.2h136.561c0-25.88 0-51.127.087-76.847 3.747-.462 7.41-.347 11.067-.472 3.036-.104 4.086 1.242 4.08 4.177-.052 28.286-.076 56.571.015 84.857.015 4.544-2.951 3.98-5.754 3.98-30.282.009-60.564.006-90.846.006-11.481 0-22.963.112-34.441-.06-3.796-.057-5.403 1.039-5.263 5.089.27 7.811.11 15.637.156 23.873z"
      />
      <path
          fill="#284FA7"
          d="M571.928 383.859c-5.062.416-10.185.416-15.915.416 0-40.628 0-81.054.07-121.959 3.401-.453 6.756-.167 10.057-.468 4.43-.405 5.727 1.323 5.695 5.677-.172 23.983-.094 47.967-.076 71.95.012 14.656.07 29.312.169 44.384z"
      />
      <path
          fill="#214BA7"
          d="M181.843 476.83c21.45-.52 42.896-.624 64.343-.668 18.613-.037 37.227-.008 56.606-.008 0 5.148 0 10.06-.083 15.432-38.016.444-75.95.428-113.883.408-6.903-.003-6.9-.012-6.923-6.736-.01-2.662-.04-5.324-.06-8.428z"
      />
    </svg>
)
export default Logo;

module.exports = {
  email: "hansusana93@gmail.com",

  socialMedia: [
    {
      name: "GitHub",
      url: "https://github.com/susanahan",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/nekotr0n_dev",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/susana-han",
    },
  ],

  navLinks: [
    {
      name: "About",
      url: "/#about",
    },
    {
      name: "Experience",
      url: "/#experience",
    },
    {
      name: "Projects",
      url: "/#projects",
    },
  ],
};
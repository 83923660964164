import { PlayIcon } from "@heroicons/react/24/outline";

const Main = () => {
  return (
      <div id="about">
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
            <span
                className="absolute px-3 font-small font-bold uppercase text-sm text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-800">
              About me 👋
            </span>
        </div>
        <section className="mb-16 scroll-mt-16 md:mb-22 lg:mb-34 lg:scroll-mt-24">
          <div>
            <p className="mb-4">
              Hello there! I'm a dynamic career changer who has journeyed from healthcare to finance,
              and now into the world of tech as a software engineer. My fascination with web development started during my Tumblr days, where I uncovered my passion for coding and crafting captivating themes. I further expanded my skills by utilizing WordPress while working as a research student, facilitating webinars for doctors to attain their certifications. This journey led me to take a leap of faith and enroll in a bootcamp. Today, I'm thrilled to be a part of the coding team at Justworks, actively translating innovative ideas into functional solutions.
            </p>
            <p className="mb-4">
              Technologies I've worked with:
            </p>
            <div className="mb-4">
              <ul className="space-y-4 text-left text-gray-500 dark:text-gray-400">

                <div className="grid grid-cols-2 gap-4">
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>JavaScript</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>React</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>Rails</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>Ruby</span>
                  </li>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>Node.js</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>Express</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>PostgreSQL</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <PlayIcon className="flex-shrink-0 w-3.5 h-3.5 text-indigo-400 dark:text-indigo-300" aria-hidden="true"/>
                    <span>HTML/CSS</span>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </section>
      </div>
  );
};
export default Main;
import React from "react";

const About = () => {
  return (
      <div>
        <h1 className="text-4xl font-bold tracking-tight text-slate-200 sm:text-5xl">
          Susana Han
        </h1>
        <h2 className="mt-3 text-lg font-medium tracking-tight text-slate-200 sm:text-xl">
          Senior Engineer at Justworks
        </h2>
        <p className="mt-4 max-w-xs leading-normal">
          Full-stack dreamer: shaping both the front-end and back-end worlds.
        </p>
      </div>
  );
};
export default About;